import { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import MarkdownRichText from '@/components/shared/MarkdownRichText';
import DateComponent from '../../DateComponent';
import { SecondaryImage } from './SinglePostStyles';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import { Content, Description, FeaturedImage, Post, Title } from './SinglePostDefaultStyles';
import { LinkButton } from '@/components/core/Button';

const SinglePostDefault = ({
  animation = 'slideIn',
  buttonLabel = 'Read More',
  className,
  post,
  press,
  career,
  small,
  big,
  noDescription,
  onClick,
  postWithBackground,
  hasDate = true,
  hasReadingTime,
  linkProps = {},
  url,
  resources,
  primary = false,
  ...props
}) => {
  if (!post) return null;
  const { title, publishedOn, publishedAt, content, shortDescription, wordCount } = post;
  const publishedDate = publishedOn || publishedAt;
  let category =
    post?.category?.displayName || post?.category?.data?.attributes?.displayName || post?.category;

  if (category?.data === null) category = null;

  const featuredImage = getImgData(post.featuredImage);

  return (
    <Link href={url} passHref legacyBehavior>
      <Post
        animation={animation}
        small={small}
        big={big}
        className={className}
        postWithBackground={postWithBackground}
        press={press}
        career={career}
        onClick={onClick}
        resources={resources}
        {...linkProps}
        {...props}
      >
        {!press && (
          <FeaturedImage>
            {featuredImage && (
              <Image
                data-testid="post-featureImage"
                src={getImgSrcWorkaround(featuredImage.url)}
                alt={featuredImage.alternativeText || ''}
                fill
                sizes="(max-width: 768px) 100vw, 50vw"
              />
            )}
          </FeaturedImage>
        )}
        <Content press={press} career={career} data-testid="post-content">
          {post?.imageList && (
            <SecondaryImage career={career}>
              <Image
                width={100}
                height={career ? 27 : 50}
                src={getImgSrcWorkaround(
                  post.imageList?.url ? post.imageList?.url : post.imageList?.data?.attributes?.url,
                )}
                alt={
                  post.imageList?.alternativeText
                    ? post.imageList?.alternativeText
                    : post.imageList?.data?.attributes?.alternativeText
                }
              />
            </SecondaryImage>
          )}
          {hasDate && (
            <DateComponent
              publishedOn={publishedDate}
              content={content}
              category={category}
              readingTime={post?.readingTime}
              hasReadingTime={hasReadingTime}
              career={career}
              wordCount={wordCount}
            />
          )}
          {title && <Title>{title}</Title>}

          {!press && !small && !noDescription && !postWithBackground && (
            <Description>
              {shortDescription ? shortDescription : <MarkdownRichText>{content}</MarkdownRichText>}
            </Description>
          )}

          {postWithBackground && (
            <LinkButton forwardedAs="span" $primary={primary}>
              {buttonLabel}
            </LinkButton>
          )}
        </Content>
      </Post>
    </Link>
  );
};

export default memo(SinglePostDefault);
